import React from "react";
import {Grid, Typography} from "@mui/material";

import {SurveyResult} from "@/types";
import {SurveyPanel, Growth, Tooltip} from "@/components/atoms";
import {reformatValue, isPositive, absValue} from "@/utils/common";


type ValuePanelProps = {
  title: string;
  description?: string;
  results: SurveyResult[];
};

type GrowthTypes = {
  [id: string]: string;
}

const growthType: GrowthTypes = {Spend: "Index", Percentage: "Percentage", Value: "Index"};
const percentageTooltip = "Percentage difference compared to similar audiences";
const indexTooltip = "Indexed against the average of similar audiences";


export const ValuePanel: React.FC<ValuePanelProps> = ({ title, description, results }) => {

  return (
    <SurveyPanel title={title} description={description}>
      {results.length === 1 ?
        <div className="panel-content panel-content-space">
          <Tooltip title={results[0].description} placement="right">
            <Typography variant='h3' align='right' sx={{color: 'white'}}>
              {reformatValue(results[0].value, results[0].value_type, 0, results[0].country)}
            </Typography>
          </Tooltip>
          <Growth
            value={reformatValue(absValue(results[0].diff), growthType[results[0].value_type], 2)}
            positive={isPositive(results[0].diff, growthType[results[0].value_type])}
            tooltipText={results[0].value_type === "Percentage" ? percentageTooltip : indexTooltip}
            typographyVariant="h6"
            arrowSize="medium"
          />
        </div>
      :
        <div className="panel-content panel-content-evenly">
          {results.map( ({name, value,value_type, diff, description}, index) => {
            return (

                <div key={`panel-${title}-${index}`} className="row row-space-evenly" style={{ width: '100%'}}>
                  <Tooltip title={description} placement="right">
                    <Typography variant='h4' align='right' sx={{color: 'white'}} mr={1}>
                      {reformatValue(value, value_type, 0, results[0].country)}
                    </Typography>
                  </Tooltip>
                  <Growth
                    value={reformatValue(absValue(diff), growthType[value_type], 2)}
                    positive={isPositive(diff, growthType[value_type])}
                    tooltipText={value_type === "Percentage" ? percentageTooltip : indexTooltip}
                    typographyVariant="subtitle1"
                    arrowSize="small"
                  />
                </div>
            )
          })}
        </div>
      }
    </SurveyPanel>
  );
};
