import React from "react";

import {SurveyResult} from "@/types";
import {SurveyPanel} from "@/components/atoms";
import {PieChart} from '@/components/molecules';

type PiePanelProps = {
  title: string;
  description?: string;
  results: SurveyResult[];
};


export const PiePanel: React.FC<PiePanelProps> = ({ title, description, results }) => {

  return (
    <SurveyPanel title={title} description={description}>
      <PieChart labels={results.map(x => x.name)} values={results.map(x => x.value)} />
    </SurveyPanel>
  );
};
