import React from "react";
import {Tooltip as T} from "@mui/material";  // TODO is this the right thing to do?
import { TooltipProps as TP } from "@mui/material/Tooltip";

type TooltipProps = {
  title: React.ReactNode;
  placement: TP['placement'];
  children: React.ReactElement<any, any>;
};

export const Tooltip: React.FC<TooltipProps> = ({ title, placement, children }) => {
  return (
    <T
      title={title}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "#474F5C",
            borderRadius: 2,
            padding: "8px",
            maxWidth: "200px",
            textAlign: "center"
          }
        }
      }}
    >
      {children}
    </T>
  );
};
