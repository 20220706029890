import React from "react";

import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

type InfoButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};


export const InfoButton: React.FC<InfoButtonProps> = ({ onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <InfoIcon sx={{color: "white"}}/>
    </IconButton>
  );
};
