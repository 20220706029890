import { AxiosRequestConfig } from "axios";
import { apiServerUrl } from "@/constants";
import { callExternalApi } from "@/services/api-service";
import { AudiencesApiResponse } from "@/interfaces/api";

export const getAudiences = async (
  accessToken: string
): Promise<AudiencesApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/audiences`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({
    config,
  })) as AudiencesApiResponse;

  return {
    data,
    error,
  };
};
