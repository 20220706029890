import { Brand, Player, Sport, Vertical, SpendCategory } from "@/types";
import { ColumnDefinitionType } from "@/components/molecules/table";

export const topSportsColumns: Array<ColumnDefinitionType<Sport, keyof Sport>> =
  [
    {
      key: "rank",
      label: "Rank",
      width: 1,
    },
    {
      key: "variable_name",
      label: "Sport",
      width: "auto",
      icon: {
        key: "variable_name",
      },
    },
    {
      key: "penetration",
      label: "Penetration",
      tooltip: "Percentage of audience that engage with this sport",
      width: 2,
    },
    {
      key: "index",
      label: "Index",
      tooltip: "Index compared to fans / sports fans in the same market",
      width: 2,
    },
  ];

export const topPlayersColumns: Array<
  ColumnDefinitionType<Player, keyof Player>
> = [
  {
    key: "rank",
    label: "Rank",
    width: 1,
  },
  {
    key: "star_name",
    label: "Player",
    width: "auto",
    image: {
      key: "star_image_url",
    },
  },
  {
    key: "penetration",
    label: "Penetration",
    tooltip: "Percentage of sport fans within the audience that follow this player",
    width: 2,
  },
  {
    key: "index",
    label: "Index",
    tooltip: "Index compared to fans / sports fans in the same market",
    width: 2,
  },
];

export const topLeaguesColumns: Array<
  ColumnDefinitionType<Player, keyof Player>
> = [
  {
    key: "rank",
    label: "Rank",
    width: 1,
  },
  {
    key: "star_name",
    label: "Name",
    width: "auto",
    image: {
      key: "star_image_url",
    },
  },
  {
    key: "penetration",
    label: "Penetration",
    tooltip: "Percentage of sport fans within the audience that follow this league",
    width: 2,
  },
  {
    key: "index",
    label: "Index",
    tooltip: "Index compared to fans / sports fans in the same market",
    width: 2,
  },
];

export const topTeamsColumns: Array<
  ColumnDefinitionType<Player, keyof Player>
> = [
  {
    key: "rank",
    label: "Rank",
    width: 1,
  },
  {
    key: "star_name",
    label: "Name",
    width: "auto",
    image: {
      key: "star_image_url",
    },
  },
  {
    key: "penetration",
    label: "Penetration",
    tooltip: "Percentage of sport fans within the audience that follow this team",
    width: 2,
  },
  {
    key: "index",
    label: "Index",
    tooltip: "Index compared to fans / sports fans in the same market",
    width: 2,
  },
];

export const topVerticalsColumns: Array<
  ColumnDefinitionType<Vertical, keyof Vertical>
> = [
  {
    key: "rank",
    label: "Rank",
    width: 1,
  },
  {
    key: "variable_name",
    label: "Vertical",
    width: "auto",
    icon: {
      key: "variable_name",
    },
  },
  {
    key: "penetration",
    label: "Penetration",
    tooltip: "Percentage of audience that engage with this vertical",
    width: 2,
  },
  {
    key: "index",
    label: "Index",
    tooltip: "Index compared to fans / sports fans in the same market",
    width: 2,
  },
];

export const topBrandsColumns: Array<ColumnDefinitionType<Brand, keyof Brand>> =
  [
    {
      key: "rank",
      label: "Rank",
      width: 1,
    },
    {
      key: "star_name",
      label: "Brand",
      width: "auto",
      image: {
        key: "star_image_url",
      },
    },
    {
      key: "penetration",
      label: "Penetration",
      tooltip: "Percentage of audience that engage with this brand",
      width: 2,
    },
    {
      key: "index",
      label: "Index",
      tooltip: "Index compared to fans / sports fans in the same market",
      width: 2,
    },
  ];


export const topTitlesColumns: Array<ColumnDefinitionType<Brand, keyof Brand>> =
  [
    {
      key: "rank",
      label: "Rank",
      width: 1,
    },
    {
      key: "star_name",
      label: "Brand",
      width: "auto",
      image: {
        key: "star_image_url",
      },
    },
    {
      key: "penetration",
      label: "Penetration",
      tooltip: "Percentage of audience that engage with this media title",
      width: 2,
    },
    {
      key: "index",
      label: "Index",
      tooltip: "Index compared to fans / sports fans in the same market",
      width: 2,
    },
  ];

export const topCategoriesColumns: Array<ColumnDefinitionType<SpendCategory, keyof SpendCategory>> =
  [
    {
      key: "rank",
      label: "Rank",
      width: 1,
    },
    {
      key: "category_name",
      label: "Category",
      width: "auto",
      icon: {
        key: "category_id",
      },
    },
    {
      key: "penetration",
      label: "Penetration",
      tooltip: "Percentage of audience that engage with this category",
      width: 3,
    },
    {
      key: "baseline_penetration",
      label: "UK Baseline",
      tooltip: "Percentage of the UK that engage with this category",
      width: 3,
    },
    {
      key: "index",
      label: "Index",
      tooltip: "Index of spend when compared to the UK",
      width: 3,
    },
  ];
