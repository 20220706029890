import React from "react";
import { InputLabel, ListSubheader, MenuItem, TextField } from "@mui/material";
import { GroupedDropdownOption } from "@/components/atoms";

interface GroupedDropdownProps {
  label: string;
  options: Array<GroupedDropdownOption> | null;
  value: string;
  onChange: (optionName: string) => void;
}

type GroupedAudience = {
  group: string;
  audiences: Array<GroupedDropdownOption>;
};
export const GroupedDropdown: React.FC<GroupedDropdownProps> = ({
  label,
  options,
  value,
  onChange,
}) => {
  // array of unique groups
  const groupedAudiences = options?.reduce((acc, option) => {
    const group = acc.find((group) => group.group === option.group);
    if (group) {
      group.audiences.push(option);
    } else {
      acc.push({ group: option.group, audiences: [option] });
    }
    return acc;
  }, [] as Array<GroupedAudience>);

  const renderSelectGroup = (group: GroupedAudience) => {
    const items = group.audiences.map((audience: GroupedDropdownOption) => {
      return (
        <MenuItem
          key={audience.id}
          value={audience.name}
          sx={{
            color: "white",
            backgroundColor: "var(--menu-bg)",
            "&:hover": {
              backgroundColor: "var(--menu-hover)",
            },
          }}
        >
          {audience.name}
        </MenuItem>
      );
    });
    return [
      <ListSubheader
        sx={{
          backgroundColor: "var(--dropdown-bg)",
          color: "white",
          fontWeight: "bold",
        }}
      >
        {group.group}
      </ListSubheader>,
      items,
    ];
  };

  // sort by group name
  const sortedGroupedAudiences = groupedAudiences?.sort((a, b) =>
    a.group > b.group ? 1 : -1
  );

  return (
    <>
      <InputLabel
        variant="filled"
        size="small"
        color="primary"
        sx={{
          color: "white",
          mb: "12px",
          ml: -1
        }}
      >
        {label}
      </InputLabel>
      <TextField
        select
        fullWidth
        variant={"filled"}
        value={value || ""}
        onChange={(e) => onChange(e.target.value as string)}
        sx={{
          mt: 1,
          // minWidth: "360px",
          "&& .Mui-focused": {
            backgroundColor: "var(--dropdown-bg)",
          },
          "& label": {
            marginBottom: "3em",
          },
        }}
        InputProps={{
          hiddenLabel: true,
        }}
        SelectProps={{
          disableUnderline: true,
          sx: {
            borderRadius: "5px",
            color: "white",
            backgroundColor: "var(--dropdown-bg)",
            "&:hover": {
              backgroundColor: "var(--dropdown-hover)",
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
          },
          MenuProps: {
            PaperProps: {
              sx: {
                backgroundColor: "var(--menu-bg)",
                color: "white",
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: "var(--menu-hover)",
                },
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: "var(--menu-select)",
                },
              },
            },
          },
        }}
      >
        {sortedGroupedAudiences?.map((group) => renderSelectGroup(group))}
      </TextField>
    </>
  );
};
