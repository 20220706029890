import React from "react";
import {Tooltip} from "@/components/atoms";
import {Paper, Typography, Card, CardHeader, CardContent} from "@mui/material";

type PanelProps = {
  title: string;
  description?: string;
  children: React.ReactNode;
};

export const SurveyPanel: React.FC<PanelProps> = ({ title, description, children }) => {
  return (
    <Card
      sx={{
        height: 200,
        minWidth: 256,
        // maxWidth: 256,
        backgroundColor: '#212B3A',
        borderRadius: '8px',
        padding: '16px 8px 16px 16px',
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
      }}
    >
      <Tooltip title={description} placement="top-start">
        <Typography variant="body1" sx={{color: 'white', height: 24}}>
          {title}
        </Typography>
      </Tooltip>
      <CardContent
        sx={{
          height: 160,
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};
