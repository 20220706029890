import React, { useEffect, useState } from "react";
import { TableHeader } from "@/components/molecules/table/table-header";
import { TableRows } from "@/components/molecules/table/table-rows";
import { ColumnDefinitionType } from "@/components/molecules/table/types";
import { CsvBuilder } from "filefy";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Download } from "@mui/icons-material";
import { SearchBar } from "@/components/atoms";

type TableProps<T, K extends keyof T> = {
  id: string;
  data: Array<T>;
  columns: Array<ColumnDefinitionType<T, K>>;
  onRowClick?: (row: T) => void;
  startSelected?: number;
  enableSearch?: boolean;
  searchPlaceholder?: string;
};

export const Table = <T, K extends keyof T>({
  id,
  data,
  columns,
  onRowClick,
  startSelected,
  enableSearch,
  searchPlaceholder,
}: TableProps<T, K>): JSX.Element => {
  const [sortKey, setSortKey] = useState<ColumnDefinitionType<T, K>>(
    columns[0]
  );
  const [dataToDisplay, setDataToDisplay] = useState<Array<T>>(data);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    const sortedData = sortData({
      tableData: data,
      sortKey: sortKey,
      reverse: sortOrder === "desc",
    });
    setDataToDisplay(sortedData);
  }, [data, sortKey, sortOrder]);

  const handleTableSorting = (column: ColumnDefinitionType<T, K>) => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortKey(column);
  };

  const sortData = ({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Array<T>;
    sortKey: ColumnDefinitionType<T, K>;
    reverse: boolean;
  }) => {
    const sortedData = tableData.sort((a, b) => {
      return a[sortKey.key] > b[sortKey.key] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  };

  const handleRowClick = (row: T) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const downloadTable = () => {
    const data = dataToDisplay.map((row) => {
      return columns.map((column) => row[column.key]);
    });
    return new CsvBuilder(`${id}.csv`)
      .setDelimeter(",")
      .setColumns(columns.map((column) => column.label))
      .addRows(data as string[][])
      .exportFile();
  };

  const escapeRegExp = (value: string) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (value: string) => {
    const searchRegex = new RegExp(escapeRegExp(value), 'i')
    const filteredData = data.filter((row) => {
      // @ts-ignore
      return searchRegex.test(row[columns[1].key]);
      // return columns.some((column) => {
      //   // @ts-ignore
      //   return row[column.key].toString().toLowerCase().includes(value);
      // });
    });
    setDataToDisplay(filteredData);
  };

  return (
    <table className="table">
      <thead className="table-header-actions">
        <tr>
          <th>
            {enableSearch && (
              <SearchBar
                placeholder={searchPlaceholder || "Search"}
                onChange={(value) => requestSearch(value)}
              />
            )}
            <Tooltip title="Download table" placement="top">
              <IconButton
                size={"small"}
                color={"primary"}
                onClick={() => {
                  downloadTable();
                }}
              >
                <Download />
              </IconButton>
            </Tooltip>
          </th>
        </tr>
      </thead>
      <TableHeader
        columns={columns}
        onSort={(column) => handleTableSorting(column)}
        sortKey={sortKey}
      />
      {dataToDisplay.length > 0 ? (
        <TableRows
          tableId={id}
          data={dataToDisplay}
          columns={columns}
          onRowSelect={onRowClick ? handleRowClick : undefined}
          startSelected={startSelected}
        />
      ) : (
        <tbody className="table-no-data">No data</tbody>
      )}
    </table>
  );
};
