import React from "react";
import {Paper, Typography} from "@mui/material";

type PanelProps = {
  title: string;
  children: React.ReactNode;
};

export const Panel: React.FC<PanelProps> = ({ title, children }) => {
  return (
    <Paper
      sx={{
        height: 240,
        minWidth: 340,
        backgroundColor: '#212B3A',
        borderRadius: '8px',
        padding: '16px 16px 16px 16px',
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
      }}
    >
      <Typography variant="body1" sx={{color: 'white'}}>
        {title}
      </Typography>
      {children}
    </Paper>
  );
};
