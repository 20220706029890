import React, {FC, useEffect, useState} from "react";
import { Brand, Vertical } from "@/types";
import { reformatData } from "@/utils/common";
import { Grid, Typography } from "@mui/material";
import { Table } from "@/components/molecules";
import {
  topBrandsColumns,
  topVerticalsColumns,
} from "@/components/molecules/table";

type fanOpportunitiesTabProps = {
  verticals: Vertical[];
};

export const FanOpportunitiesTab: FC<fanOpportunitiesTabProps> = ({
  verticals,
}) => {
  const [selectedVertical, setSelectedVertical] = useState<Vertical>();
  const [brands, setBrands] = useState<Brand[]>([]);

  const handleVerticalChange = (vertical: Vertical) => {
    setSelectedVertical(vertical);
    setBrands(reformatData(vertical.brands));
  };

  useEffect(() => {
    setSelectedVertical(verticals[0]);
    setBrands(reformatData(verticals[0].brands));
  }, []);

  return (
    <Grid container item xs={12} spacing={6} mb={12}>
      <Grid item xs={12} sm={12} md={6} lg={6} mb={4}>
        <Typography variant="subtitle1" mb={2}>
          Top Verticals
        </Typography>
        <Table
          id="verticals"
          data={verticals}
          columns={topVerticalsColumns}
          onRowClick={(vertical: Vertical) => handleVerticalChange(vertical)}
          startSelected={0}
        />
      </Grid>
      {selectedVertical && (
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="h6"
            mb={1}
            mt={2}
            sx={{
              color: "var(--color-primary)",
            }}
          >
            {selectedVertical.variable_name}
          </Typography>
          <Typography variant="subtitle1" mb={2}>
            Top Brands
          </Typography>
          <Table
            id="top-verticals"
            data={brands}
            columns={topBrandsColumns}
            enableSearch={true}
            searchPlaceholder="Search for brands by name"
          />
        </Grid>
      )}
    </Grid>
  );
};
