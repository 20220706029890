import { useState } from "react";
import { Tooltip } from "@/components/atoms";
import { ColumnDefinitionType } from "@/components/molecules/table/types";

export type TableHeaderProps<T, K extends keyof T> = {
  columns: Array<ColumnDefinitionType<T, K>>;
  onSort?: (column: ColumnDefinitionType<T, K>) => void;
  sortKey?: ColumnDefinitionType<T, K>;
};

export const TableHeader = <T, K extends keyof T>({
  columns,
  onSort,
}: TableHeaderProps<T, K>): JSX.Element => {
  const [sortColumn, setSortColumn] = useState<ColumnDefinitionType<T, K>>(
    columns[0]
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const handleSort = (column: ColumnDefinitionType<T, K>) => {
    if (onSort) {
      const sortOrder =
        column.key === sortColumn.key && sortDirection === "asc"
          ? "desc"
          : "asc";

      setSortColumn(column);
      setSortDirection(sortOrder);
      onSort(column);
    }
  };

  const renderSortIcon = (column: ColumnDefinitionType<T, K>) => {
    if (column.key === sortColumn.key) {
      return sortDirection === "asc" ? (
        <div className="table-header-sort-icon">&#9650;</div>
      ) : (
        <div className="table-header-sort-icon">&#9660;</div>
      );
    }
    return "";
  };

  const headers = columns.map((column, index) => {
    return (
      <Tooltip title={column.tooltip} placement="top">
        <th
          className={`table-cell-${column.width}`}
          key={`headCell-${index}`}
          onClick={() => handleSort(column)}
        >
          <span>{column.label}</span>
          {renderSortIcon(column)}
        </th>
      </Tooltip>
    );
  });

  return (
    <thead className="table-header">
      <tr className="table-header-row">{headers}</tr>
    </thead>
  );
};
