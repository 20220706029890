import React, { useEffect, useState } from "react";
import { getAudiences } from "@/services/audience-service";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AudienceDropdownOption,
  GroupedDropdown,
} from "@/components/atoms/dropdown";
import { Audience } from "@/types";

interface AudienceDropdownProps {
  value: AudienceDropdownOption;
  onChange: (audience: AudienceDropdownOption) => void;
}

export const AudienceDropdown: React.FC<AudienceDropdownProps> = ({
  value,
  onChange,
}) => {
  const [audiences, setAudiences] = useState<Array<AudienceDropdownOption>>([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true;

    const getAudienceData = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getAudiences(accessToken);

      if (!isMounted) {
        return;
      }

      if (data) {
        setAudiences(
          data.map((audience: Audience) => ({
            id: audience.audience_id,
            name: audience.audience_name,
            group: audience.audience_group,
            countries: audience.audience_countries
          }))
        );
      }
    };

    getAudienceData();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  const handleAudienceChange = (name: any) => {
    const audience = audiences.find((audience) => audience.name === name);
    if (audience) onChange(audience);
  };

  return (
    <GroupedDropdown
      label="Select Your Audience"
      options={audiences}
      value={value ? value.name : ""}
      onChange={(name) => handleAudienceChange(name)}
    />
  );
};
