import React from "react";
import { Typography } from "@mui/material";
import { NumericFormat } from 'react-number-format';

import { SurveyPanel } from "@/components/atoms";

type SpendPanelProps = {
  title: string;
  description?: string;
  value: number;
};

export const SpendPanel: React.FC<SpendPanelProps> = ({ title, description, value }) => {

  return (
    <SurveyPanel title={title} description={description}>
      <div className="panel-content panel-content-end">
          <Typography variant='h4' align='right' sx={{color: 'white'}}>
            <NumericFormat value={value} thousandSeparator={true} prefix={'£'} displayType="text" />
          </Typography>
      </div>
    </SurveyPanel>
  );
};
