import { ChangeEvent, FC, useState } from "react";
import { TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

type SearchBarProps = {
  placeholder: string;
  onChange: (value: string) => void;
};
export const SearchBar: FC<SearchBarProps> = ({ placeholder, onChange }) => {
  const [value, setValue] = useState<string>("");

  const handleInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  return (
    <div className="search-container">
      <TextField
        hiddenLabel
        fullWidth
        placeholder={placeholder}
        size="small"
        value={value}
        onChange={(event) => handleInput(event)}
        sx={{
          "& .MuiOutlinedInput-root": {
            color: "white",
            backgroundColor: "var(--menu-bg)",
            borderRadius: "4px",
            "& fieldset": {
              border: "none",
              outline: "none",
            },
          },
        }}
        InputProps={{
          endAdornment: <Search style={{ fill: "#00afcc" }} />,
        }}
      />
    </div>
  );
};
