import React from "react";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { BarChartDataType } from "@/components/molecules/charts/charts-types";

ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const ActivityChart: React.FC<BarChartDataType> = ({labels, values, baseline}) => {

  const minVal = Number((Math.min.apply(Math, values) - 0.1).toFixed(1));
  const maxVal = Number((Math.max.apply(Math, values)).toFixed(1));

  return (
    <div className="chart-container bar-chart">
      <Bar
        options={{
          animation: false,
          responsive: true,
          maintainAspectRatio: false,
          datasets: {
            bar: {
              maxBarThickness: 30,
              barPercentage: 1,
              categoryPercentage: 0.35
            }
          },
          scales: {
            y: {
              min: minVal < 0 ? 0 : minVal,
              max: maxVal,
              ticks: {
                color: "white",
                font: {
                  size: 10
                },
                maxTicksLimit: 6,
                padding: 5,
                callback: function(value, index, ticks) { return Number(value).toFixed(1)}
              },
              border: {
                color: "white",
              },
              grid: {
                color: "white",
                drawOnChartArea: false,
                tickLength: 4
              },
            },
            x: {
              ticks: {
                color: "white",
                font: {
                  size: 10
                }
              },
              border: {
                color: "white",
              },
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
              position: "top",
              labels: {
                boxWidth: 20,
                boxHeight: 5,
                useBorderRadius: true,
                borderRadius: 2,
                color: "white",
                font: {
                  size: 10
                }
              },
            },
            tooltip: {
              backgroundColor: "#2C3644",
              callbacks: {
                // title: function(context) {return ''},
                // labelPointStyle: function(context) {},
                label: function(context) {
                  return ' ' + context.parsed.y.toFixed(2);
                }
              }
            }
          },
        }}
        data={{
          labels: labels,
          datasets: [
            {
              label: "Audience",
              data: values,
              backgroundColor: "#00B0CC",
              borderRadius: 4
            },
            // {
            //   label: "Baseline",
            //   data: baseline,
            //   backgroundColor: "#B1BAC6",
            //   borderRadius: 4
            // },
          ],
        }}
      />
    </div>
  );
};
