import React from "react";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Pie } from "react-chartjs-2";

import { PieChartDataType } from "@/components/molecules/charts/charts-types";

ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type PieChartColors = {
  [id: string]: string;
}

const colors: PieChartColors = {Followers: "#49505B", Casuals: "#00526A", Fanatics: "#00B0CC"};

export const PieChart: React.FC<PieChartDataType> = ({labels, values}) => {
  return (
    <div className="pie-chart">
      <Pie
        data={{
          labels: labels,
          datasets: [
            {
              label: "",
              data: values,
              backgroundColor: labels.map(x => colors[x]),
              borderColor: labels.map(x => colors[x]),
              borderWidth: 1,
            },
          ],
        }}
        options={{
          animation: false,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "right",
              labels: {
                boxWidth: 20,
                boxHeight: 5,
                useBorderRadius: true,
                borderRadius: 2,
                color: "white",
                font: {
                  size: 10
                }
              },
            },
            tooltip: {
              backgroundColor: "#2C3644",
              callbacks: {
                // title: function(context) {return ''},
                // labelPointStyle: function(context) {},
                label: function(context) {
                  return ' ' + (context.parsed * 100).toFixed(1) + '%';
                }
              }
            }
          },
        }}
      />
    </div>
  );
};
