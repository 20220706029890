import { convertToPercentage, round } from "@/utils/common";
import { Activity, Age, Gender, Medium } from "@/types";

//:TODO -  need to refactor these methods into one function

export const computeActivityData = (activitiesData: Activity[]) => {
  // get each variable name from activities
  // const labels = Array.from(
  //   new Set(activitiesData.map((activity) => activity.variable_name))
  // );
  const labels = ["Creator", "Amplifier", "Watcher"];

  // for each label, get the penetration
  const values = labels.map((label) => {
    // get the activities for the label
    const index = activitiesData.find(
      (activity) => activity.variable_name === label
    )?.index;

    // convert to percentage and round to 2 decimal places
    // const value = convertToPercentage(penetration ? penetration : 0, 2);
    const value = round(index ? index : 0, 2);
    return Number(value);
  });

  // for each label, get the baseline
  const baseline = labels.map((label) => {
    // get the activities for the label
    const penetration = activitiesData.find(
      (activity) => activity.variable_name === label
    )?.baseline;

    // convert to percentage and round to 2 decimal places
    const value = convertToPercentage(penetration ? penetration : 0, 2);
    return Number(value);
  });

  return {
    labels,
    values,
    baseline,
  };
};

export const computeAgeData = (ageData: Age[]) => {
  // get each variable name from age
  const labels = Array.from(new Set(ageData.map((age) => age.variable_name)));

  // for each label, get the penetration
  const values = labels.map((label) => {
    // get the age for the label
    const index = ageData.find((age) => age.variable_name === label)?.index;

    // convert to percentage and round to 2 decimal places
    // const value = (penetration ? penetration * 100 : 0).toFixed(2);
    const value = round(index ? index : 0, 2);
    return Number(value);
  });

  // for each label, get the baseline
  const baseline = labels.map((label) => {
    // get the age for the label
    const penetration = ageData.find(
      (age) => age.variable_name === label
    )?.baseline;

    // convert to percentage and round to 2 decimal places
    const value = (penetration ? penetration * 100 : 0).toFixed(2);

    return Number(value);
  });

  return {
    labels,
    values,
    baseline,
  };
};

export const computeGenderData = (genderData: Gender[]) => {
  let groups: { name: string; values: number[] }[] = [];

  // define the types and labels
  const types = ["Audience", "Baseline"];
  const labels = ["Female", "Male"];

  // for each label, get the penetration
  const values = labels.map((label) => {
    // get the age for the label
    const penetration = genderData.find(
      (gender) => gender.variable_name === label
    )?.penetration;

    // convert to percentage and round to 2 decimal places
    const value = (penetration ? penetration * 100 : 0).toFixed(2);

    return Number(value);
  });

  // for each label, get the baseline
  const baseline = labels.map((label) => {
    // get the age for the label
    const penetration = genderData.find(
      (gender) => gender.variable_name === label
    )?.baseline;

    // convert to percentage and round to 2 decimal places
    const value = (penetration ? penetration * 100 : 0).toFixed(2);

    return Number(value);
  });

  // add values to groups
  groups.push({
    name: "Audience",
    values: values,
  });
  groups.push({
    name: "Baseline",
    values: baseline,
  });

  return {
    groups,
    types,
  };
};

export const computeMediaData = (
  mediaData: Medium[],
  key: "index" | "penetration"
) => {
  const labels = [
    "Website",
    "Magazine",
    "Media Brand",
    "Newspaper",
    "Podcast",
    "Radio",
    "TV",
    "Influencer",
    "Blog",
  ];

  const values = labels.map((label) => {
    if (key === "index") {
      const index = mediaData.find(
        (medium) => medium.variable_name === label
      )?.index;
      // convert to percentage and round to 2 decimal places
      const value = round(index ? index : 0, 2);
      return Number(value);
    }

    const penetration = mediaData.find(
      (medium) => medium.variable_name === label
    )?.penetration;

    // convert to percentage and round to 2 decimal places
    const value = Number(
      `${convertToPercentage(penetration ? penetration : 0, 0)}`
    );
    return Number(value);
  });

  return {
    labels,
    values,
  };
};
