import icons from "@/assets/icons/utils";

export const SurveyIcon = ({ id }: { id: string }) => {
  const iconId = id.replace(/ /g, "-").replace("/", "").toLowerCase();

  return (
    <div className="survey-icon">
      <img
        className="survey-icon-image"
        src={icons[iconId]}
        alt={iconId}
      />
    </div>
  );
};
