import React from "react";
import cx from "classnames";

type GenderChartProps = {
  groups: Array<{
    name: string;
    values: Array<Number>;
  }>;
  types: string[];
};

type GenderChartColors = {
  [id: string]: string;
}

const colors: GenderChartColors = {Audience: "#00B0CC", Baseline: "#B1BAC6"};

export const GenderChart: React.FC<GenderChartProps> = ({ groups, types }) => {
  return (
    <div className="gender-chart">
      <div className="chart-label chart-label-male">Male</div>
      <div className="chart-label chart-label-female">Female</div>
      {types.map((type, index) => {
        const female = Number(groups[index].values[0].toFixed(1));
        const male = Number(groups[index].values[1].toFixed(1));

        return (
          <div className="chart-segment" key={type}>
            <div className="segment-bar-wrapper">
              <div className="bars-window">
                <div
                  className="bar bar-value-a"
                  style={{ backgroundColor: colors[type] }}
                />
                <div
                  className="bar bar-value-b"
                  style={{
                    transform: `translateX(-${female}%)`,
                  }}
                />
              </div>
              <div
                className="bar-divider-wrapper"
                style={{ transform: `translate(${male}%)` }}
              >
                <div className="bar-divider">
                  <div
                    className={cx("segment-value", {
                      "segment-value-female": female > 50,
                    })}
                  >
                    {female > 50 ? `${female}% Female` : `${male}% Male`}
                  </div>
                </div>
              </div>
            </div>
            <div className="segment-name">{type}</div>
          </div>
        );
      })}
    </div>
  );
};
