import { AxiosRequestConfig } from "axios";
import { apiServerUrl } from "@/constants";
import { callExternalApi } from "@/services/api-service";
import { InsightsApiResponse } from "@/interfaces/api";

export const getInsight = async (
  accessToken: string,
  countryId: number,
  audienceId: number
): Promise<InsightsApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/insight?audience=${audienceId}&country=${countryId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({
    config,
  })) as InsightsApiResponse;

  return {
    data,
    error,
  };
};
