import React from "react";

import {Dialog, DialogTitle, DialogContent, Typography, Grid} from '@mui/material';

type InfoDialogProps = {
  open: boolean;
  onClose: (value: string) => void;
};

const glossaryInfo = [
  {
    name: 'Social Audience Size',
    description: 'The total customer base identified.'
  },
  {
    name: 'Social Audience Growth',
    description: 'Growth or decline in the size of the audience in the last 12 months displayed as a percentage.'
  },
  {
    name: 'Modelled Market Size',
    description: 'The Social Audience size extrapolated using market statistics to represent the overall market population.'
  },
  {
    name: 'Penetration',
    description: 'The percentage of the given audience that follow / spend / have an interest in the corresponding variable.'
  },
  {
    name: 'Index',
    description: 'A score comparing the penetration of the audience to the penetration of the baseline for the corresponding variable. A value higher than 1 represents an over index, lower than 1 represents an under index.'
  },
  {
    name: 'Creators',
    description: 'Social Media users who create and post original content.'
  },
  {
    name: 'Amplifiers',
    description: 'Social Media users who repost content created by Creators (via Shares, Retweets etc.) but don’t post their own content.'
  },
  {
    name: 'Watchers',
    description: 'Social Media users who don\'t post or repost content and are active only through consuming the content of Creators and Amplifiers.'
  },
  {
    name: 'Vertical',
    description: 'An industry or sector characterised as something a fan might have an interest in.'
  }
];


const categoryInfo = [
  {
    name: 'Concert or Theatre Tickets',
    description: 'Retailers and Venues who sell Concert & Theatre Tickets.',
    examples: 'Examples: Theatre Tickets Direct; Ticket Master'
  },
  {
    name: 'Consumer Electronics, Appliances & Accessories',
    description: 'Retailers specialising in consumer electronics and appliances.',
    examples: 'Examples: Currys; AO.com'
  },
  {
    name: 'Entertainment & Resorts',
    description: 'Physical Entertainment and Resort establishments.',
    examples: 'Examples: Tenpin; Thorpe Park'
  },
  {
    name: 'International Air Travel',
    description: 'Airlines and travel agencies for international air travel.',
    examples: 'Examples: Qatar Airways; SkyScanner'
  },
  {
    name: 'Online Audio Subscription Services',
    description: 'All online audio subscription services.',
    examples: 'Examples: Spotify; Amazon Music'
  },
  {
    name: 'Online Gaming',
    description: 'All online gaming purchases including games, gaming services and in game purchases.',
    examples: 'Examples: Steam, PlayStation Store'
  },
  {
    name: 'Online Shopper',
    description: 'Customers who spend 2 or more times the UK average online.',
    examples: ''
  },
  {
    name: 'Online Video Subscription Services',
    description: 'All online video subscription services.',
    examples: 'Examples: Netflix; Disney+'
  },
  {
    name: 'Professional Sports',
    description: 'Professional sporting games and arena amenities.',
    examples: 'Examples: Wembley Stadium; Lords Cricket Ground'
  },
  {
    name: 'Sporting Goods Online',
    description: 'Online transactions for retailers of athletic equipment and sporting apparel.',
    examples: 'Examples: Pro Direct Soccer; Sports Direct'
  }
]


export const InfoDialog: React.FC<InfoDialogProps> = ({ open, onClose }) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      scroll="body"
      PaperProps={{
        sx: {
          backgroundColor: '#212B3A',
          color: "white",
          borderRadius: '12px'
        }
      }}
    >
      <DialogTitle sx={{fontSize: '24px', textTransform: 'uppercase', fontFamily: 'cera-pro-bold'}}>
        Glossary
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          {glossaryInfo.map(({name, description}, index) => {
            return (
              <Grid item xs={12} key={`glossary-${index}`}>
                <Typography variant="h6">{name}</Typography>
                <Typography variant="body1" sx={{color: '#efefef'}}>{description}</Typography>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>

      <DialogTitle sx={{fontSize: '24px', textTransform: 'uppercase', fontFamily: 'cera-pro-bold'}}>
        Spend Categories
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          {categoryInfo.map(({name, description, examples}, index) => {
            return (
              <Grid item xs={12} key={`category-${index}`}>
                <Typography variant="h6">{name}</Typography>
                <Typography variant="body1" sx={{color: '#efefef'}}>{description}</Typography>
                <Typography variant="body1" sx={{color: '#82e1f2'}}>{examples}</Typography>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
