import React, { useState } from "react";
import { ColumnDefinitionType } from "@/components/molecules/table/types";
import { InterestIcon } from "@/components/atoms/interest-icon/interest-icon";

type TableRowsProps<T, K extends keyof T> = {
  tableId: string;
  data: Array<T>;
  columns: Array<ColumnDefinitionType<T, K>>;
  onRowSelect?: (row: T) => void;
  startSelected?: number;
};

export const TableRows = <T, K extends keyof T>({
  tableId,
  data,
  columns,
  onRowSelect,
  startSelected
}: TableRowsProps<T, K>): JSX.Element => {
  const [selectedRow, setSelectedRow] = useState(startSelected != undefined ? startSelected : -1);

  const imageCell = (
    row: T,
    column: ColumnDefinitionType<T, K>,
    index: number
  ): JSX.Element => {
    const image = column?.image?.key ? (row[column.image.key] as string) : "";
    const placeholderImage = "https://placehold.co/150x150?text=N/A";

    const onImageError = (
      event: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
      event.currentTarget.src = placeholderImage;
    };

    return (
      <td
        className={`table-cell table-cell-${column.width}`}
        key={`${tableId}-cell-${index}`}
      >
        <div className="table-cell-image">
          <img
            src={image}
            alt={image}
            className="table-cell-image"
            onError={onImageError}
          />
        </div>
        {row[column.key]}
      </td>
    );
  };

  const iconCell = (
    row: T,
    column: ColumnDefinitionType<T, K>,
    index: number
  ): JSX.Element => {
    return (
      <td className={`table-cell-${column.width}`} key={`cell-${index}`}>
        <div className="table-cell-image">
          <InterestIcon id={(row[column.key] as string).toLowerCase()} />
        </div>
        {row[column.key]}
      </td>
    );
  };

  const onRowClick = (row: T, index: number) => {
    if (onRowSelect) {
      setSelectedRow(index);
      onRowSelect(row);
    }
  };

  const rows = data.map((row, index) => {
    return (
      <tr
        className={`table-body-row ${
          selectedRow === index && "table-body-row-selected"
        }`}
        key={`${tableId}-row-${index}`}
        onClick={() => onRowClick(row, index)}
      >
        {columns.map((column, index2) => {
          if (column?.image?.key) {
            return imageCell(row, column, index2);
          }

          if (column?.icon?.key) {
            return iconCell(row, column, index);
          }

          // add percentage sign to the end of penetration or index value
          if (column.key === "penetration") {
            return (
              <td
                className={`table-cell-${column.width}`}
                key={`${tableId}-cell-${index2}`}
              >
                {`${row[column.key]}%`}
              </td>
            );
          }

          if (column.key === "baseline_penetration") {
            return (
              <td
                className={`table-cell-${column.width}`}
                key={`${tableId}-cell-${index2}`}
              >
                {`${row[column.key]}%`}
              </td>
            );
          }

          return (
            <td className={`table-cell-${column.width}`} key={`${tableId}-cell-${index2}`}>
              {row[column.key]}
            </td>
          );
        })}
      </tr>
    );
  });

  return <tbody className="table-body">{rows}</tbody>;
};
