import React, { ReactElement } from "react";

type TabsProps = {
  selectedTab: number;
  onTabChange: (tabIndex: number) => void;
  children: ReactElement[];
};

export const Tabs: React.FC<TabsProps> = ({
  children,
  selectedTab,
  onTabChange,
}) => {
  return (
    <div className="tabs-container">
      <ul className="tabs-list">
        {children.map((item, index) => (
          <React.Fragment key={`tab-${index}`}>
            <li className="tab-title-container">
              <button
                className={
                  selectedTab === index ? "tab-title active-tab" : "tab-title"
                }
                onClick={() => onTabChange(index)}
              >
                {item.props.title}
              </button>
            </li>
            <div className="tab-divider" />
          </React.Fragment>
        ))}
      </ul>

      <div className="tabs-panel">
        <div className="tab-content">{children[selectedTab]}</div>
      </div>
    </div>
  );
};
