import React from "react";
import {Grid, Typography} from "@mui/material";

import {SurveyResult} from "@/types";
import {SurveyPanel} from "@/components/atoms";
import { SurveyIcon } from "@/components/atoms/survey-icon/survey-icon";
import {reformatValue, isPositive, absValue} from "@/utils/common";


type ValuePanelProps = {
  title: string;
  description?: string;
  results: SurveyResult[];
};


export const TopPanel: React.FC<ValuePanelProps> = ({ title, description, results }) => {

  return (
    <SurveyPanel title={title} description={description}>
      <div className="panel-content panel-content-evenly">
        {results.slice(0, 3).map( ({name, value,value_type, diff }, index) => {
          return (
            <div key={`panel-${title}-${index}`} className="row row-space-between" style={{ width: '100%'}}>
              <div className="row">
                <SurveyIcon id={name.toLowerCase()}/>
                <Typography variant='body1' align='left' sx={{fontSize: '12px', color: 'white'}} mr={1}>
                  {name}
                </Typography>
              </div>
              <Typography variant='body1' align='left' sx={{fontSize: '12px', color: 'white'}} mr={1}>
                {reformatValue(value, value_type)}
              </Typography>
            </div>
          )
        })}
      </div>
    </SurveyPanel>
  );
};
