import React from "react";
import { InputLabel, MenuItem, TextField } from "@mui/material";
import { DropdownOption } from "@/components/atoms";

interface DropdownProps {
  label: string;
  options: Array<DropdownOption> | null;
  value: string;
  onChange: (optionName: string) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  value,
  onChange,
}) => {
  return (
    <div>
      <InputLabel
        variant="filled"
        size="small"
        color="primary"
        sx={{
          color: "white",
          mb: "12px",
          ml: -1
        }}
      >
        {label}
      </InputLabel>
      <TextField
        select
        fullWidth
        id="dropdown"
        variant="filled"
        value={value}
        onChange={(event) => onChange(event.target.value as string)}
        sx={{
          mt: 1,
          // minWidth: "360px",
          "&& .Mui-focused": {
            backgroundColor: "var(--dropdown-bg)",
          },
          "& label": {
            marginBottom: "3em",
          },
        }}
        InputProps={{
          hiddenLabel: true,
        }}
        SelectProps={{
          disableUnderline: true,
          sx: {
            borderRadius: "5px",
            color: "white",
            backgroundColor: "var(--dropdown-bg)",
            "&:hover": {
              backgroundColor: "var(--dropdown-hover)",
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
          },
          MenuProps: {
            PaperProps: {
              sx: {
                backgroundColor: "var(--menu-bg)",
                color: "white",
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: "var(--menu-hover)",
                },
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: "var(--menu-select)",
                },
              },
            },
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem key={`dropdown-${option.id}`} value={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};
