import React from "react";
import {Typography} from "@mui/material";
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { TypographyTypeMap } from "@mui/material/Typography";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";

import { Tooltip } from "@/components/atoms";


type GrowthProps = {
  value: any;
  positive: boolean;
  text?: string;
  tooltipText?: string;
  typographyVariant?: TypographyTypeMap["props"]["variant"];
  arrowSize?: SvgIconTypeMap["props"]["fontSize"];
};

export const Growth: React.FC<GrowthProps> = ({ value, positive, text, tooltipText, typographyVariant='subtitle1', arrowSize='medium' }) => {
  return (
    <div className="row row-end">
      {positive ?
        <ArrowUpwardRoundedIcon fontSize={arrowSize} sx={{color: '#4ED469'}}/>
      :
        <ArrowDownwardRoundedIcon fontSize={arrowSize} sx={{color: '#F3546D'}}/>
      }
      {text ?
        <div>
          <Tooltip title={tooltipText} placement="right">
            <div>
              <Typography variant={typographyVariant} align='right' mb={-2} sx={{color: positive ? '#4ED469' : '#F3546D'}}>
                {value}
              </Typography>
              <Typography variant='overline' align='right' sx={{color: positive ? '#4ED469' : '#F3546D'}}>
                {text}
              </Typography>
            </div>
          </Tooltip>
        </div>
      :
        <Tooltip title={tooltipText} placement="right">
          <Typography variant={typographyVariant} align='right' sx={{color: positive ? '#4ED469' : '#F3546D'}}>
            {value}
          </Typography>
        </Tooltip>
      }
    </div>
  );
};
