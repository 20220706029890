export const convertToPercentage = (value: number, decimalPoints: number) =>
  (value * 100).toFixed(decimalPoints);

export const round = (value: number, decimalPoints: number) =>
  value.toFixed(decimalPoints)

export const reformatData = (data: any[]) => {
  if (data) {
    return data.map((d) => ({
      ...d,
      index: Number(round(d.index, 2)),
      penetration:
        d.penetration === 1.0
          ? Number(`${convertToPercentage(d.penetration, 0)}`)
          : Number(`${convertToPercentage(d.penetration, 1)}`),
      baseline_penetration:
        d.baseline_penetration
          ? Number(`${convertToPercentage(d.baseline_penetration, 1)}`)
          : 0,
    }));
  } else {
    return [];
  }
};

type currencyType = {
  [id: string]: string;
}
const currency: currencyType = {GB: "£", US: "$", ES: "€", IT: "€", BR: "R$"};

export const reformatValue = (value: number, type: string, decimals=0, country='GB') => {

  if (type === 'Index') {
    return `${round(value, 2)}x`;
  } else if (type === 'Spend') {
    return `${currency[country]}${round(value, 2)}`;
  } else if (type === 'Percentage') {
    return `${convertToPercentage(value, 1)}%`;
  } else {
    return round(value, decimals);
  }
};

export const isPositive = (value: number, type: string) => {
  if (type === 'Index') {
    return value > 1;
  } else {
    return value > 0;
  }

};

export const absValue = (value: number) => { return Math.abs(value) };
