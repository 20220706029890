import React from "react";
import { Grid, Typography } from "@mui/material";
import {Growth, Tooltip} from "@/components/atoms";
import { AudienceSize } from "@/types";
import {absValue, isPositive, reformatValue, convertToPercentage} from "@/utils/common";

interface AudienceSizeStatsProps {
  audienceSize: AudienceSize;
}

export const AudienceSizeStats: React.FC<AudienceSizeStatsProps> = ({
  audienceSize,
}) => {
  return (
    <Grid container item xs={12} spacing={2} justifyContent="flex-start" mt={1}>
      <Grid item md={3.5} lg={3} alignItems="flex-start" alignContent="center">
        <Tooltip title="The size of the audience on social media" placement="top-start">
          <Typography variant={"body1"} mb={"20px"}>
            Social Audience Size
          </Typography>
        </Tooltip>
        <div className="row">
            <Typography variant='h4' mr={1}>
              {audienceSize.social_size.toLocaleString("en-US")}
            </Typography>
          <Tooltip title="Over a 12 month period" placement="right">
            <div>
              <Growth
                value={reformatValue(absValue(audienceSize.growth), 'Percentage', 2)}
                positive={isPositive(audienceSize.growth, 'Percentage')}
                text="growth"
                typographyVariant="h6"
                arrowSize="large"
              />
            </div>
          </Tooltip>
        </div>
      </Grid>
      <Grid item xs={4} alignItems="flex-start" alignContent="center">
        <Tooltip title="The size of the audience when modelled based on population statistics" placement="top-start">
          <Typography variant={"body1"} mb={"20px"}>
            Modelled Market Size
          </Typography>
        </Tooltip>
        <Typography variant={"h4"}>
          {audienceSize.modelled_size.toLocaleString("en-US")}
        </Typography>
      </Grid>
    </Grid>
  );
};
