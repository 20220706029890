
const obj: Record<string, string> = {};

// TODO Is there a better way to do this?

const interestContext = require.context("./interests", true, /\.svg$/);
interestContext.keys().forEach((key: string) => {
  const name = key.replace("/ /", "-").replace("./", "").replace(".svg", "");
  obj[name] = interestContext(key);
});

const surveyContext = require.context("./survey", true, /\.svg$/);
surveyContext.keys().forEach((key: string) => {
  const name = key.replace("/ /", "-").replace("./", "").replace(".svg", "");
  obj[name] = surveyContext(key);
});

export default obj;
