import interests from "@/assets/icons/utils";

export const InterestIcon = ({ id }: { id: string }) => {
  const interestId = id.replace(/ /g, "-").replace("/", "").toLowerCase();

  return (
    <div className="table-cell-icon">
      <img
        className="interest-icon"
        src={interests[interestId]}
        alt={interestId}
      />
    </div>
  );
};
