import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { HomePage } from "./pages/home-page";
import { CallbackPage } from "./pages/callback-page";
import { NotFoundPage } from "./pages/not-found-page";
import { UnauthorizedPage } from "./pages/unauthorized-page";
import { ProtectedRoute } from "@/components/routes";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: "#00afcc",
          "&:hover": {
            backgroundColor: "rgba(0,175,204,0.67)",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          color: "#00afcc",
          "&:hover": {
            color: "rgba(0,175,204,0.67)",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#ccc",
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#00afcc",
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: "#fff",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "cera-pro",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 1024,
      md: 1120,
      lg: 1408,
      xl: 1696,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <ProtectedRoute path="/" exact component={HomePage} />
        <Route path="/callback" component={CallbackPage} />
        <Route path="*" component={NotFoundPage} />
        {/* If the user is rejected by log-in, redirect them to the unauthorized page */}
        <Route path="/unauthorized" component={UnauthorizedPage} />

        {/* Redirect /login to AppWrapper to delegate auth decisions */}
        <Redirect from="/login" to="/" />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
